@import "nlib/config";
@import "animations";

.onboarding {
  display: flex;
  flex: auto;
  width: 100%;
  margin: auto;
  padding: $contentDefaultIndent * 2;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto !important;
    margin-bottom: 0;
    font-size: 1.5rem;
    svg {
      margin-bottom: $contentDefaultIndent;
      animation: rotate 2s linear infinite;
      color: $uiPrimaryColor;
      font-size: 4rem;
    }
  }
}
